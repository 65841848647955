import React from 'react';
import SpotifyPlayer from "react-spotify-player";
import YouTube from "react-youtube";

export const Music = (isMobile) => {
    const view = 'list'; // or 'coverart'
    const theme = 'black'; // or 'white'


    const opts = {
        height: '400',
        // width: '640',
        // height: '100vh',
        width: isMobile ? '60%' : '80%',
        playerVars: {
            autoplay: 0,
        },
    };

    return (
        <section className='portfolio-mf sect-pt4 route' id='kunst'>
            <h1 className='section-header'>Music</h1>

                <div className="row">
                    <div className="col-sm-12">
                        {/*{description}*/}
                        <div>


                        <YouTube videoId="MeRAcCVW-Zg" opts={opts} />
                        </div>
                        <div>
               

                        <YouTube videoId="PtCpEpLAtEQ" opts={opts} />
                        </div>
                        <div className="text-card-container-quote">

                        <YouTube videoId="OhS2aPRR98U" opts={opts} />
                        </div>

                        
                    </div>
                </div>
            <br/>
        </section>

    );
}
