import React from 'react';
import bild from "../../assets/Bilder/portraits/portrait4.JPG";

export const About = () => {

    const description = <p>

        Nice to meet you I'm Sophia, the musician behind <b><i>zebrablau</i></b> (former <i>Phia Blu</i>). I'm a singer, songwriter and producer based in Munich, originally from the northern Italian Alps.
        <br/>
        <br/>
        I like dreamy electronic sounds and write lyrics that sound a but like fairytales with a dystopian touch.
        <br/>
        <br/>
        I released my frist self-produced single "Katharsis" in April 2024, more is coming soon.

        <br/><br/>
        {/* Songwriting is my way radically embracing my emotions instead of labelling them as good or bad. There’s a depth
to melancholic moments that becomes beautiful when it’s translated into words and sounds, even when it’s scary to share it at first.
        <br/><br/> */}
        <br/><br/>

    </p>;

    // const quote = <p>
    //     "Songwriting is my way radically embracing my emotions instead of labelling them as good or bad. There’s a depth
    //     to melancholic moments that becomes beautiful when it’s translated into words and sounds. Sharing our honest
    //     feelings and thoughts this way is scary, but makes us come closer in the end."

    // </p>;

    return (<section id='about'>
            <main className='biografie-card'>
                <h1 className='section-header'>About</h1>

                <div className='text-card-container'>
                    <div className="row">
                        <div className="col-sm-7">

                            {description}
                            {/* <div className='text-card-container-quote'>

                                {quote}
                            </div> */}


                        </div>
                        <div className="col-sm-5">
                            <img style={{width: '100%'}}
                                 src={bild}
                            />
                            <span className='image-copyright'>© Lukács Krisztina-Arabella</span>
                            <br/>

                        </div>
                    </div>
                </div>
            </main>

        </section>

    );
}