import React from 'react';
import bild from "../../assets/gansamwasserklein.jpg";

export const Events = () => {

    return (
        <section className='portfolio-mf sect-pt4 route' id='events'>
            <h1 className='section-header'>Events</h1>

            <div className='text-card-container'>

                <div className="row">
                    <div className="col-sm-7">
                                <table>
                                    <tr>
                                        <th>2024</th>
                                    </tr>
                                    <tr>
                                        <td>13.01.2024</td>
                                        <td>
                                        <a href="https://www.facebook.com/events/304802495780495/">innen: welt, Café Kult, München</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>09.02.2024</td>
                                        <td>
                                        <a href="https://www.songkick.com/de/concerts/41494796-felix-kramer-at-dekadenz">Opener für Felix Kramer, Dekadenz, Brixen</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>2023</th>
                                    </tr>
                                    <tr>
                                        <td>18.12.2023</td>
                                        <td>
                                        <a href="https://maerchenbazar.de/">Märchenbazar (Wannda Bühne), München</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>17.12.2023</td>
                                        <td>
                                        <a href="https://milla-club.de/milla-song-slam-8/">Milla Song Slam, München</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>23.11.2023</td>
                                        <td>
                                        <a href="https://www.facebook.com/events/137243272764797/">Café Mellow, München</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>17.11.2023</td>
                                        <td>
                                        <a href="https://rausgegangen.de/en/events/indie-konzertabend-0">Treibhaus Acts, München</a>
                                        </td>
                                    </tr>
                                     <tr>
                                        <td>28.10.2023</td>
                                        <td>
                                        <a href="https://www.instagram.com/womensbodiesproject/">Women's Bodies Project, Astra Brixen</a>
                                        </td>
                                    </tr>
        
            

                                    <tr>
                                        <td>29.09.2023</td>
                                        <td>
                                        Live im Lihotzky, München
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>07.06.2023</td>
                                        <td>Café Gans am Wasser, München</td>
                                    </tr>
                                    <tr>
                                        <td>31.05.2023</td>
                                        <td>Alte Utting, München</td>
                                    </tr>
                                    {/* <tr>
                                        <td></td>
                                        <td><i>No upcoming events</i></td>
                                    </tr> */}
                                    <tr>
                                        <th>Earlier</th>
                                    </tr>

                                    <tr>
                                        <td>28.02.2020</td>
                                        <td>Live im Lihotzky, München</td>
                                    </tr>
                                    <tr>
                                        <td>15.12.2019</td>
                                        <td>Zauberhafter Weihnachtsmarkt, Werksviertel Mitte, München</td>
                                    </tr>
                                    <tr>
                                        <td>16.11.2019</td>
                                        <td>Beats For Needs: Benefizkonzert der UNICEF HSG München</td>
                                    </tr>
                                    <tr>
                                        <td>21.09.2019</td>
                                        <td>Music Universe DAH, Kulturschranne, Dachau</td>
                                    </tr>
                                    <tr>
                                        <td>07.09.2019</td>
                                        <td>Szene Check Feierwerk Funkstation, München</td>
                                    </tr>
                                    <tr>
                                        <td>29.06.2019</td>
                                        <td>Konvolut bei der Brucker Kulturnacht 2019, Fürstenfeldbruck</td>
                                    </tr>
                                    <tr>
                                        <td>04.05.2019</td>
                                        <td>Lange Musik der Nacht - Minna Thiel, München</td>
                                    </tr>
                                    <tr>
                                        <td>04.04.2019</td>
                                        <td>Singer // Songwriter // Lifesaver, München</td>
                                    </tr>
                                    <tr>
                                        <td>01.04.2019</td>
                                        <td>Opener für Beans On Toast, Feierwerk, München</td>
                                    </tr>
                                    <tr>
                                        <td>26.10.2018</td>
                                        <td>Opener für Moorea Masa, UFO, Bruneck</td>
                                    </tr>
                                </table>
                    </div>
                    <div className="col-sm-5">
                        <img style={{width: '100%'}}
                             src={bild}
                        />
                        <span className='image-copyright'>© Sophia Knapp</span>
                        <br/>

                    </div>
                </div>
            </div>
            <br/>
        </section>

    );
}
