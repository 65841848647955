import React from 'react';
import './NavBar.css';
import {Link} from 'react-scroll';


import youtube from "../../assets/socialmedia/youtube.png";
import spotify from "../../assets/socialmedia/spotify.png";
import applemusic from "../../assets/socialmedia/applemusic.png";
import instagram from "../../assets/socialmedia/instagram.png";
import facebook from "../../assets/socialmedia/facebook.png";
import whatsapp from "../../assets/socialmedia/whatsapp.png";


export const NavBar = () => {
    return (
        <nav className="nav-bar-container">

            <div className="nav-title-container">
                zebrablau
            </div>

            <div className='nav-link-container'>
                <div className='nav-link-container'>
                    <Link className='nav-bar-link' offset={-50} to="kunst" spy={true} smooth={true}><i>Music</i></Link>
                    <Link className='nav-bar-link' offset={-50} to="about" spy={true} smooth={true}>About</Link>
                    <Link className='nav-bar-link' offset={-50} to="events" spy={true} smooth={true}>Events</Link>
                    <Link className='nav-bar-link' offset={-50} to="subscribe" spy={true} smooth={true}>Newsletter</Link>
                    <Link className='nav-bar-link' offset={-50} to="contact" spy={true} smooth={true}>Contact</Link>

                    <a className='nav-bar-link nav-icon' href="https://www.instagram.com/zebrablau_/" rel="noreferrer"
                       target="_blank"><img className='instagram' src={instagram} alt="instagram"/></a>
                    {/* <a className='nav-bar-link nav-icon' href="https://www.facebook.com/phiablu/" rel="noreferrer"
                       target="_blank"><img  className='facebook' src={facebook} alt="facebook"/></a> */}
                    <a className='nav-bar-link nav-icon' href="https://open.spotify.com/artist/2Va3jYvYauCUhDVuyMoRYL" rel="noreferrer"
                       target="_blank"><img className='spotify' src={spotify} alt="spotify"/></a>
                    <a className='nav-bar-link nav-icon' href="https://www.youtube.com/@zebrablau" rel="noreferrer"
                       target="_blank"><img className='youtube' src={youtube} alt="youtube"/></a>
                                           <a className='nav-bar-link nav-icon' href="https://chat.whatsapp.com/IXyJIJUo3QkIG3GFJoaf8f" rel="noreferrer"
                       target="_blank"><img className='whatsapp' src={whatsapp}
                                            alt="whatsapp"/></a>
                    {/* <a className='nav-bar-link nav-icon' href="https://music.apple.com/us/artist/phia-blu/1672099656" rel="noreferrer"
                       target="_blank"><img className='applemusic' src={applemusic}
                                            alt="applemusic"/></a> */}


                </div>
            </div>

        </nav>
    )
}