import React from 'react';
import bild2 from "../../assets/Bilder/portraits/guitars.jpg";


export const Subscribe = (isMobile) => {

    const text = "I use WhatsApp to announce new music and upcoming gigs: "

    return (
        <section className='portfolio-mf sect-pt4 route' id='subscribe'>
            <h1 className='section-header'>Newsletter</h1>
            <div className="text-card-container ">
                <div className="row">
                    <div className="col-sm-4">
                        <img style={{width: '100%'}}
                             src={bild2}
                        />
                        <span className='image-copyright'>© Sophia Knapp</span>
                        <br/>
                        <br/>
                    </div>

                    <div className="col-sm-8">
                    {text}
                    <b><a href="https://chat.whatsapp.com/IXyJIJUo3QkIG3GFJoaf8f">Sign up for newsletter</a></b>

                        {/* <div className="subscribe-form-container">
                            
                        </div> */}
                    </div>

                </div>

            </div>
        </section>);
}
