import React from 'react';

export const Kontakt = () => {
    return (
        <section className='portfolio-mf sect-pt4 route' id='contact'>
            <main>

                <h1 className='section-header'>Contact</h1>

                <div className='text-card-container'>
                    <div className="row justify-content-center">
                        <span>
                                I'm always happy to play live, just get in touch: <b><a href="mailto:booking@zebrablau.com">booking@zebrablau.com</a></b>
                        </span>
                    </div>
                </div>
            </main>

        </section>

    );
}