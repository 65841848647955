import React from 'react';

const copyright= "© Sophia Knapp 2023";


export const Impressum = () => {
    return (
        <div className="impressum" style={{textAlign: "center"}}>
            <br/>
            <br/>
            <br/>
            <span> IMPRESSUM
            </span>
        
            <br/>
            <span> Für den Inhalt dieser Website verantwortlich ist Sophia Knapp, Dohlenweg 15, 80937 München. Kontakt: booking@zebrablau.com
            </span>
            <br/>
    

            
            <span>
            Die Inhalte dieser Seite unterliegen dem Copyright. Die Verwendung bzw. Vervielfältigung von Texten und Bildmaterial nur nach Absprache. 
            </span>
            <br/>
            <span> Copyright der Fotos, siehe Bildunterschriften.
            </span>
            <br/>
            <span> Webdesign und Programmierung: Sophia Knapp
            </span>
            <br/>
            <span>
            Keine Haftung für Inhalte externer Links.
            </span>
            {/* {copyright} */}
            <br/>
            <br/>
            <br/>
            </div>
    );
}