import React from 'react';

import { NavBar } from '../NavBar/NavBar';
import { Home } from '../Home/Home';
import {Music} from "../Kunst/Music";

import {About} from "../Biografie/About";
import {Kontakt} from "../Kontakt/Kontakt";
import {Impressum} from "../Impressum/Impressum";
import {NavBarMobile} from "../NavBar/NavBarMobile";
import {Subscribe} from "../Subscribe/Subscribe";
import {Events} from "../Events/Events";
import {Media} from "../Media/Media";

export const Main = (props) => {
	const isMobile = props.width < 800;
	return (
		<div>
			{isMobile ? <NavBarMobile /> : <NavBar />}
			<Home />
			<br/>
			<About />

			<Music isMobile/>
			{/*<Songwriting/>*/}
			{/*<Media />*/}
			<Events />
			<Subscribe isMobile/>
			<Kontakt />
			<Impressum />
		</div>
	);
}

