import React from 'react';
import './NavBar.css';

import instagram from "../../assets/socialmedia/instagram.png";
import facebook from "../../assets/socialmedia/facebook.png";
import spotify from "../../assets/socialmedia/spotify.png";
import youtube from "../../assets/socialmedia/youtube.png";
import applemusic from "../../assets/socialmedia/applemusic.png";
import whatsapp from "../../assets/socialmedia/whatsapp.png";




export const NavBarMobile = () => {
	return (
		<nav className="nav-bar-container-mobile">

			<div className="col-sm-12">
			<div className="row">
			<div className="nav-title-container-mobile">
				zebrablau
			</div>
			</div>
			{/*<div className="row">*/}
				<div className='nav-link-container-mobile'>
					<a className='nav-bar-link-mobile nav-icon' href="https://www.instagram.com/zebrablau_/" rel="noreferrer"
					   target="_blank"><img className='instagram' src={instagram} alt="instagram"/></a>
					{/* <a className='nav-bar-link-mobile nav-icon' href="https://www.facebook.com/phiablu/" rel="noreferrer"
					   target="_blank"><img  className='facebook' src={facebook} alt="facebook"/></a> */}
					<a className='nav-bar-link-mobile nav-icon' href="https://open.spotify.com/artist/2Va3jYvYauCUhDVuyMoRYL" rel="noreferrer"
					   target="_blank"><img className='spotify' src={spotify} alt="spotify"/></a>
					<a className='nav-bar-link-mobile nav-icon' href="https://www.youtube.com/@zebrablau" rel="noreferrer"
					   target="_blank"><img className='youtube' src={youtube} alt="youtube"/></a>
					{/* <a className='nav-bar-link-mobile nav-icon' href="https://music.apple.com/us/artist/phia-blu/1672099656" rel="noreferrer"
					   target="_blank"><img className='applemusic' src={applemusic}
											alt="applemusic"/></a> */}
					<a className='nav-bar-link-mobile nav-icon' href="https://chat.whatsapp.com/IXyJIJUo3QkIG3GFJoaf8f" rel="noreferrer"
                       target="_blank"><img className='whatsapp' src={whatsapp}
                                            alt="whatsapp"/></a>
											
				</div>
			{/*</div>*/}

			</div>
		</nav>
	)
}